import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Fungsi untuk memotong teks dengan batas maksimum dan menambahkan ellipsis
const truncateText = (text, maxLength) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

function PostList({ language }) {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Halaman saat ini
  const postsPerPage = 8; // Jumlah post per halaman

  useEffect(() => {
    fetch('https://shortnfun.com/api/posts')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(error => console.error('Error fetching posts:', error));
  }, []);

  // Menghitung indeks post yang akan ditampilkan berdasarkan halaman
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Fungsi untuk mengganti halaman
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h2>{language === 'en' ? 'All Posts' : 'Semua Post'}</h2>
      <div className="post-list">
        {currentPosts.map((post) => (
          <div key={post.slug} className="post-item">
            <Link to={`/post/${post.slug}`}>
              {post.section_image_url && (
                <img
                  src={`${process.env.PUBLIC_URL}/${post.section_image_url}`}
                  alt={`Post ${post.slug}`}
                  className="post-image"
                />
              )}
              <h3>
                {language === 'en' ? post.title : post.title_indonesian}
              </h3>
              <p>{post.created_at}</p>
              {post.section_content && (
                <p className="post-summary">
                  {language === 'en' ? truncateText(post.section_content, 100) : truncateText(post.section_content_indonesian, 100)}
                </p>
              )}
            </Link>
          </div>
        ))}
      </div>

      {/* Pagination */}
      <div className="pagination">
        {Array(Math.ceil(posts.length / postsPerPage))
          .fill()
          .map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`page-button ${index + 1 === currentPage ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
      </div>

      {/* Tempat Iklan */}
      <div className="ad-section">
        <h3>{language === 'en' ? 'Advertisement' : 'Iklan'}</h3>
        <div className="ad-banner">
          {/* Placeholder for your ad */}
          <p>{language === 'en' ? 'Your ad could be here!' : 'Iklan Anda bisa di sini!'}</p>
        </div>
      </div>
    </div>
  );
}

export default PostList;
