import { useEffect } from 'react';

const HistatsTracker = () => {
  useEffect(() => {
    // Script untuk Histats tracking
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//s10.histats.com/js15_as.js';

    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<a href="/" target="_blank"><img src="//sstatic1.histats.com/0.gif?4894588&101" alt="counter" border="0"></a>`;

    const headElement = document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0];
    headElement.appendChild(script);
    headElement.appendChild(noscript);

    // Histats initialization
    window._Hasync = window._Hasync || [];
    window._Hasync.push(['Histats.start', '1,4894588,4,0,0,0,00010000']);
    window._Hasync.push(['Histats.fasi', '1']);
    window._Hasync.push(['Histats.track_hits', '']);

    return () => {
      // Clean up jika komponen di-unmount
      headElement.removeChild(script);
      headElement.removeChild(noscript);
    };
  }, []);

  return null; // Komponen ini tidak menampilkan apapun
};

export default HistatsTracker;