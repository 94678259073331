import { useEffect } from 'react';

const KofiWidget = () => {
  useEffect(() => {
    // Menambahkan script Ko-fi ke dalam body
    const script = document.createElement('script');
    script.src = 'https://storage.ko-fi.com/cdn/scripts/overlay-widget.js';
    script.async = true;

    // Penanganan error ketika script gagal dimuat
    script.onerror = () => {
      console.error('Failed to load Ko-fi script.');
    };

    document.body.appendChild(script);

    // Event handler ketika script berhasil di-load
    script.onload = () => {
      if (window.kofiWidgetOverlay) {
        window.kofiWidgetOverlay.draw('shortnfun', {
          'type': 'floating-chat',
          'floating-chat.donateButton.text': 'Support me',
          'floating-chat.donateButton.background-color': '#fcbf47',
          'floating-chat.donateButton.text-color': '#323842',
        });
      }
    };

    // Cleanup script ketika komponen di-unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
};

export default KofiWidget;