import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Helmet } from 'react-helmet';

function PostDetail({ language }) {
  const { slug } = useParams();
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    fetch(`https://shortnfun.com/api/posts/${slug}`)
      .then(response => response.json())
      .then(data => setPostData(data))
      .catch(error => console.error('Error fetching post details:', error));
  }, [slug]);

  if (!postData) {
    return <div>Loading...</div>;
  }

  const { post, sections } = postData;

  return (
    <div className="post-detail-container">
      <Helmet>
        <title>{post.title} | Shortnfun</title>
        <meta name="description" content={post.section_content} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.section_content} />
        <meta property="og:image" content={post.section_image_url} />
        <meta property="og:url" content={`https://www.shortnfun.com/post/${post.slug}`} />
      </Helmet>

      <h1>{language === 'en' ? post.title : post.title_indonesian}</h1>
      <Link to="/" className="back-link">{language === 'en' ? 'Back to Posts' : 'Kembali ke Post'}</Link>

      {sections.map((section) => (
        <div key={section.id} className="section">
          <h2>{language === 'en' ? section.section_title : section.section_title_indonesian}</h2>
          <img src={`/` + section.image_url} alt={section.section_title} className="section-image" />
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            className="section-content"
          >
            {language === 'en' ? section.content : section.content_indonesian}
          </ReactMarkdown>
          {/* Tempat Iklan */}
          <div className="ad-section">
            <h3>{language === 'en' ? 'Advertisement' : 'Iklan'}</h3>
            <div className="ad-banner">
              {/* Placeholder for your ad */}
              <p>{language === 'en' ? 'Your ad could be here!' : 'Iklan Anda bisa di sini!'}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PostDetail;
