import React, { useState } from 'react';
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PostList from './PostList';
import PostDetail from './PostDetail';
import KofiWidget from './KofiWidget';
import HistatsTracker from './HistatsTracker';

function App() {
  const [language, setLanguage] = useState('en'); // Default English

  const toggleLanguage = () => {
    setLanguage(prevLanguage => (prevLanguage === 'en' ? 'id' : 'en'));
  };

  return (
    <Router>
      <div className="App">
        <header>
          <h1>Shortnfun</h1>
          <p>Enjoy short funny stories!</p>
          {/* Widget Ko-fi */}
          <KofiWidget />
          <div className="language-toggle">
            <label className="switch">
              <input
                type="checkbox"
                onChange={toggleLanguage}
                checked={language === 'id'}
              />
              <span className="slider"></span>
            </label>
            <span className="label-text">{language === 'en' ? 'EN' : 'ID'}</span>
          </div>
        </header>
        <Routes>
          <Route exact path="/" element={<PostList language={language} />} />
          <Route path="/post/:slug" element={<PostDetail language={language} />} />
        </Routes>
        <HistatsTracker />
      </div>
    </Router>
  );
}

export default App;
